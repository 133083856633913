body {
  font-family: 'Quicksand', sans-serif !important;
  margin: 0;
}

body,
html {
  font-size: 0.9em;
}

input.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}

.form-control {
  border-radius: 24px !important;
  padding: 20px !important;

  &:disabled {
    background: none !important;
    color: grey !important;
  }
}

button:focus {
  box-shadow: none !important;
}

.btn-primary {
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 25px !important;
  background: #e43631 !important;

  &:hover {
    background: #bc0017 !important;
  }
}

@supports (-moz-appearance:none) {
  input[type="date"].form-control {
    padding: 12px 25px 30px 15px !important;
  }
}

.phoneInput {
  padding: 22px 20px 22px 70px !important;
  width: 100% !important;

  &:disabled {
    color: grey !important;
  }
}

.phoneCountry {
  padding: 0 10px !important;
}

.phoneDropdown li {
  display: flex;
}

.phoneDropdown li>* {
  position: static !important;
}

.phoneDropdown li>div {
  margin-top: -4px !important;
}

.phoneDropdown li>span {
  background: transparent !important;
}

.selected-flag:before,
.selected-flag.open:focus,
.selected-flag.open:before {
  border: none !important;
  box-shadow: none !important;
}

.react-select__control {
  padding: 2px 10px;
  border-radius: 25px !important;
  text-align: left;
}

.grecaptcha-badge {
  visibility: hidden;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-1-5 {
  flex: 1.5;
}

.flex-5 {
  flex: 5;
}

.f-09-r {
  font-size: 0.9rem;
}

.f-1r {
  font-size: 1rem;
}

.f-w-600 {
  font-weight: 600;
}

.cursor-help {
  cursor: help;
}