.SvSpinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  .SvSpinner {
    shape-rendering: auto;
    align-self: center;
  }

  .LoadingText {
    align-self: center;
    font-size: 1.5rem;
  }
}
